import React from 'react';
import PropTypes from 'prop-types';
import styles from './RichText.module.scss';

const RichText = ({ html }) => (
    <div
        className={styles['RichText']}
        dangerouslySetInnerHTML={{ __html: html }}
    />
);

RichText.propTypes = {
    html: PropTypes.string,
};

RichText.defaultProps = {
    html: '',
};

export default RichText;
