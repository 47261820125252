import React from 'react';
import { ucFirst } from '../../utils/caseconverters';
import classNames from 'classnames';

// import i18n from '../../i18n';
import PropTypes from 'prop-types';
import styles from './Label.module.scss';

const Label = ({ title, onClick, color, active }) => {
    return (
        title && (
            <div
                onClick={onClick}
                className={classNames(
                    styles['Label'],
                    styles['Label--' + ucFirst(color)],
                    { [styles['Label--Active']]: active }
                )}>
                <span className={styles['Label__Title']}>{title}</span>
            </div>
        )
    );
};

Label.propTypes = {
    title: PropTypes.string,
    onClick: PropTypes.func,
    color: PropTypes.oneOf(['purple', 'blue', 'green', 'orange']),
    active: PropTypes.bool,
};

Label.defaultProps = {
    title: null,
    color: null,
    onClick: () => {},
    active: false,
};

export default Label;
