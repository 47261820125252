import React from 'react';

// import i18n from '../../i18n';
import PropTypes from 'prop-types';
import styles from './MassInfo.module.scss';
import RichText from '../RichText';

const MassInfo = ({ content }) => (
    <div className={styles['MassInfo']}>
        <RichText html={content} />
    </div>
);

MassInfo.propTypes = {
    content: PropTypes.string,
};

MassInfo.defaultProps = {
    content: '',
};

export default MassInfo;
